import React, { useMemo } from "react"
import { graphql, PageProps } from "gatsby"
import { ProjectPageProps } from "../types/pages"
import PageLayout from "../layouts/page-layout"
import PageBanner from "../components/shared/page-banner"
import GoogleMap from "../components/shared/google-map"
import { AccordionProps } from "../types/shared-singulars"
import Accordion from "../components/shared/accordion"

const ProjectImplementation: React.FC<PageProps<ProjectPageProps>> = ({
  data,
}) => {
  const { projectContent, dynamicContent, projectAcc } =
    data?.PAGE_CONTENT?.projectOptions

  const initialCenter = {
    lat: Number(dynamicContent[0].locations[0].lat.toFixed(2)),
    lng: Number(dynamicContent[0].locations[0].lng.toFixed(2)),
  }

  const accordions = useMemo(() => {
    return projectAcc?.map(acc => {
      return {
        title: acc.title,
        description: acc.description,
      }
    })
  }, [JSON.stringify(projectAcc)])

  return (
    <PageLayout seo={data?.PAGE_METADATA?.seo}>
      <PageBanner>
        <h1
          className="page-banner__title"
          data-aos="fade-up"
          data-aos-delay="100"
          data-aos-easing="ease-out"
        >
          {data?.PAGE_METADATA?.title}
        </h1>
        <GoogleMap
          initialCenter={initialCenter}
          markers={dynamicContent[0].locations}
        />
        <p
          className="page-banner__description"
          data-aos="fade-up"
          data-aos-delay="100"
          data-aos-easing="ease-out"
        >
          {projectContent}
        </p>
      </PageBanner>
      <div className="accordion--wrapper">
        {accordions &&
          accordions.map((item: AccordionProps, index: number) => (
            <Accordion
              key={index}
              id={index}
              titileAccordions={item.title}
              descriptionAccordions={item.description}
            />
          ))}
      </div>
    </PageLayout>
  )
}

export const query = graphql`
  query ($uri: String!, $locale: String!) {
    PAGE_METADATA: wpPage(
      uri: { eq: $uri }
      locale: { locale: { eq: $locale } }
    ) {
      id
      slug
      title
      link
      locale {
        locale
      }
      seo {
        metaDesc
        metaKeywords
        canonical
        opengraphDescription
        opengraphTitle
        opengraphType
        title
        twitterDescription
        twitterTitle
      }
    }
    PAGE_CONTENT: wpPage(
      uri: { eq: $uri }
      locale: { locale: { eq: $locale } }
    ) {
      projectOptions {
        projectContent
        dynamicContent {
          ... on WpPage_Projectoptions_DynamicContent_LocationsOptions {
            locations {
              budget
              client
              contactPerson
              country
              description
              lat
              lng
              partner
              period
              title
            }
          }
        }
        projectAcc {
          description
          title
        }
      }
    }
  }
`

export default ProjectImplementation
