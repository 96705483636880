import React from "react"
import "./page-banner.scss"
import { PageHeaderProps } from "../../types/components"

const PageBanner: React.FC<PageHeaderProps> = ({ children }) => {
  return (
    <div className="page-banner">
      <div className="page-banner__wrapper container--xl">{children}</div>
    </div>
  )
}

export default PageBanner
