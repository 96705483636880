import React, { useCallback, useReducer } from "react"
import "./google-map.scss"
import {
  GoogleMap as Map,
  useJsApiLoader,
  Marker,
  InfoWindow,
} from "@react-google-maps/api"
import mapStyles from "../../utils/map-styles.json"
import icon from "../../assets/images/red-dot.svg"
import { GoogleMapProps, MapActionType } from "../../types/components"
import { MapMarkerProps } from "../../types/shared-singulars"
import { mapReducer } from "../../utils/reducers"

const GoogleMap: React.FC<GoogleMapProps> = ({ initialCenter, markers }) => {
  const [state, dispatch] = useReducer(mapReducer, {
    mapContainerClassName: "google-map__container",
    zoom: 3,
    center: initialCenter,
    map: null,
    infoWindow: null,
  })

  const maps_key = "AIzaSyANtn9dHqRUA2ohqzUM9j-R7D1BBosE6sA"

  const { isLoaded, loadError } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: maps_key || "", //Google Map key
  })

  const onLoad = useCallback((map: { [key: string]: any }) => {
    dispatch({ type: MapActionType.SET_MAP_SETTINGS, payload: map })
  }, [])

  const onUnmount = useCallback(() => {
    dispatch({ type: MapActionType.SET_MAP_SETTINGS, payload: null })
  }, [])

  const focusMarker = (marker: MapMarkerProps, index: number) => {
    dispatch({
      type: MapActionType.SET_MULTIPLE,
      payload: {
        center: {
          lat: marker.lat,
          lng: marker.lng,
        },
        infoWindow: index,
      },
    })

    if (state.map?.zoom >= 8) {
      if (state.map?.zoom === 20) return
      state.map?.setZoom(state.map?.zoom + 2)
    } else {
      state.map?.setZoom(8)
    }
  }

  return (
    <div
      className="google-map"
      data-aos="fade-in"
      data-aos-delay="200"
      data-aos-easing="ease-out"
    >
      {isLoaded ? (
        <Map
          mapContainerClassName={state.mapContainerClassName}
          zoom={state.zoom}
          center={state.center}
          onLoad={onLoad}
          onUnmount={onUnmount}
          options={{ styles: mapStyles }}
          onClick={() =>
            dispatch({ type: MapActionType.SET_INFO_WINDOW, payload: null })
          }
        >
          {markers &&
            markers.map((marker: MapMarkerProps, index: number) => (
              <Marker
                key={index}
                position={{ lat: marker.lat, lng: marker.lng }}
                icon={icon}
                onClick={() => focusMarker(marker, index)}
              >
                {state.infoWindow === index && (
                  <InfoWindow>
                    <div className="google-map__info-window">
                      {marker?.title && (
                        <div className="google-map__info-window__title">
                          <h5>{marker.title}</h5>
                        </div>
                      )}
                      <div className="google-map__info-window__content">
                        <div className="google-map__info-window__content__details">
                          {[
                            marker?.client,
                            marker?.contactPerson,
                            marker?.country,
                            marker?.partner,
                            marker?.period,
                            marker?.budget,
                          ].map(
                            (item: string, index: number) =>
                              item && <span key={index}>{item}</span>
                          )}
                        </div>
                        {marker?.description && (
                          <div
                            className="google-map__info-window__content__description"
                            dangerouslySetInnerHTML={{
                              __html: marker.description,
                            }}
                          />
                        )}
                      </div>
                    </div>
                  </InfoWindow>
                )}
              </Marker>
            ))}
        </Map>
      ) : (
        <div className="google-map__loading">
          <span>Loading map...</span>
        </div>
      )}
      {loadError && (
        <div className="google-map__error">
          <span>Map could not be loaded</span>
        </div>
      )}
    </div>
  )
}

export default GoogleMap
